import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Unesco from "../../images/LandingPage/unesco-marquises.png";
import Unesco2 from "../../images/LandingPage/unesco_fr.png";
import { Carousel } from 'react-responsive-carousel';
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import {StyledLandingCard} from "components/LandingPage/styles";

const LandingSlider = ({footerDisplay}) => {

  const allTaxonomyTermSiteInternet = useStaticQuery(
    graphql`
      query {
        drupal {
          taxonomyTermQuery(
            filter: {
              conditions: {
                operator: EQUAL
                value: ["site_internet"]
                field: "vid"
              }
            }
          ) {
            entities {
              ...TaxonomyTermSiteInternet
            }
          }
        }
      }
    `,
  )

  const all_website_info = allTaxonomyTermSiteInternet.drupal.taxonomyTermQuery.entities.map(
    (term, index) => {
      const website_info = {
        title: term.name,
        color: term.fieldCouleur.color,
        image: term.fieldMediaImage.entity.fieldMediaImage.landing.url,
        url_page: term.fieldLien.url.path,
        key: term.entityId,
      }

      const targetUrl = website_info.url_page;

      return (
        <StyledLandingCard color={website_info.color} imageUrl={website_info.image} key={website_info.key}>

          <div className="overlay" >
            <a href={targetUrl} />
          </div>

          <a href={targetUrl} >
            <div className="bien">
              <div className="content-bien">
                <div className="title">
                  {website_info.title}
                </div>
                {website_info.key === '1' &&
                  <div className="subtitle">
                    Inscrite au patrimoine de l'unesco !
                  </div>
                }
              </div>

              <div className="logo">
                {website_info.key === '1' ? (<img src={Unesco2} alt=""/>) : (<img src={Unesco} alt=""/>)}

              </div>

            </div>
          </a>
        </StyledLandingCard>
      )
    },
  )

  return <>
    <Carousel
      showThumbs={false}
      infiniteLoop={true}
      autoPlay={false}
      showStatus={false}
      swipeable={true}>
    {all_website_info}
    </Carousel>
  </>
}

export default LandingSlider
