import React from "react"
// import components
import LandingPage from "components/LandingPage"
import ViewsListAllWebsite from "templates/views/ViewsListAllWebsite"

const Index = () => (
  <LandingPage />
)

export default Index
