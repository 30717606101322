import React from "react"
// import PropTypes from "prop-types"
import BgTattoo from "images/LandingPage/bg-circleTattoo.png"
import { StyledBigTitle } from "./styles"

const BigTitle = () => (
  <StyledBigTitle>
    <div className="backgroundImage">
      <img src={BgTattoo} alt="#" className="img-fluid" />
    </div>
    <div className="zone">Polynésie française</div>
    <div className="maeva">Maeva</div>
    <div className="welcome">
      Welcome 
      {' '}
      <br />
      {' '}
      Bienvenue
    </div>
  </StyledBigTitle>
  )

BigTitle.propTypes = {}

export default BigTitle
