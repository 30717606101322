import React from "react"
// import PropTypes from "prop-types"
import {StyledLandingPage} from "./styles"
import BigTitle from "./BigTitle"
import LandingSlider from "templates/views/LandingSlider";
import Unesco from "images/LandingPage/logo-unesco.png"
import Pays from "images/LandingPage/pays.svg"
import LogoDCP from "images/LandingPage/logo-dcp.png"

const LandingPage = ({children}) => (
  <StyledLandingPage>
    <div className="content">
      <BigTitle/>
    </div>

    <LandingSlider/>

    <div className="footer">
      © Direction de la Culture et du Patrimoine

      <div className="logo-footer">
        <img src={Pays} alt=""/>
        <img src={Unesco} alt=""/>
        <img src={LogoDCP} alt=""/>
      </div>
    </div>
  </StyledLandingPage>
)

LandingPage.propTypes = {}

export default LandingPage
